import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { groupBy, compose, values, map, slice, toLower, replace } from "ramda";
import { graphql } from "gatsby";
import SEO from "components/seo";
import Layout from "../components/layout.js";
import { rhythm } from "utils/typography";
import Card from "components/card";
import { colors, PageNav, NavItem } from "../style/theme.js";
import useFeeds from "../components/hooks/useFeeds";
import ClipLoader from "react-spinners/ClipLoader";
import { mq, extractUrl } from "utils/helper";

const H3 = styled.h3({
  color: colors.primary,
  margin: 0,
  [mq[1]]: {
    marginLeft: "2.2rem",
  },
});

const MoreLink = styled.a({
  color: colors.primary,
  fontWeight: "bold",
  ":hover": { textDecoration: "underline" },
  [mq[1]]: {
    marginLeft: "2.2rem",
    marginTop: "2rem",
    display: "none",
  },
});

const ArticlesTable = styled.div({
  display: "grid",
  gridTemplateColumns: "repeat(4, 1fr)",
  gridRowGap: rhythm(3),
  gridColumnGap: rhythm(2),
  marginTop: rhythm(3),
  marginBottom: rhythm(2),
  [mq[2]]: {
    gridTemplateColumns: "repeat(3, 1fr)",
  },
  [mq[1]]: {
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  [mq[0]]: {
    display: "block",
  },
});

const nav = [
  { title: "General Updates", id: "general-updates" },
  { title: "Content Moderation", id: "content-moderation" },
];

function GeneralUpdates({ nodes }) {
  return (
    <div id="general-updates">
      <H3>General Updates</H3>
      <ArticlesTable>
        {nodes.map((node) => (
          <Card
            key={node.id}
            node={node}
            css={{
              marginRight: "auto",
            }}
          />
        ))}
      </ArticlesTable>
    </div>
  );
}

function ContentModeration({ nodes }) {
  return (
    <div id="content-moderation">
      <H3>Content Moderation</H3>
      <ArticlesTable>
        {nodes.map((node) => (
          <Card
            key={node.id}
            node={node}
            css={{
              marginRight: "auto",
            }}
          />
        ))}
      </ArticlesTable>
    </div>
  );
}

function Archive({ id, title, feedResponse }) {
  const { data, isLoading, isError, isReady } = feedResponse;
  const href = data?.link && `${data.link}/en/investigations`

  return (
    <div key={id} id={id}>
      <div
        css={{
          display: "flex",
          justifyContent: "space-between",
          [mq[1]]: {
            display: "block",
          },
        }}
      >
        <H3>{title}</H3>
        {href && (
          <MoreLink
            href={href}
            rel="noopener noreferrer"
            target="_blank"
          >
            {" "}
            More +
          </MoreLink>
        )}
      </div>
      <ArticlesTable>
        {isError && (
          <div css={{ color: colors.light, fontStyle: "italic", paddingLeft: rhythm(1), paddingRight: rhythm(1) }}>No content found</div>
        )}
        {isLoading && (
          <ClipLoader
            size={150}
            color={colors.primary}
            loading={isLoading}
          />
        )}
        {isReady && (
          slice(0, 4, data.item).map((node) => (
            <Card
              key={node.guid}
              node={node}
              css={{
                marginRight: "auto",
              }}
            />
          ))
        )}
      </ArticlesTable>
    </div>
  );
}

function News({ data }) {
  const feeds = useFeeds();
  const contentModeration = data.reports.edges.map((n) => n.node);
  const generalUpdates = data.generalUpdates.edges.map((n) => n.node);

  return (
    <div css={{ backgroundColor: colors.dark }}>
      <SEO title="News" />
      <img
        src="/assets/engage-background.gif"
        css={{
          position: "absolute",
          top: "15%",
          left: "-15%",
          width: "100%",
          height: "auto",
          zIndex: 0,
          opacity: 0.45,
        }}
      />
      <Layout css={{ zIndex: 2 }}>
        <div
          css={{
            maxWidth: "1200px",
            margin: "100px auto",
            position: "relative",
            zIndex: 2,
            [mq[1]]: {
              //              margin: "4rem 2rem"
            },
          }}
        >
          <div css={{ display: "flex", justifyContent: "space-between" }}>
            <h2
              css={{
                color: colors.primary,
                margin: 0,
                marginBottom: "100px",
                fontSize: "48px",
                display: "block",
                [mq[1]]: {
                  marginLeft: "2.2rem",
                },
              }}
            >
              News
            </h2>
            <div>
              <PageNav css={{ justifyContent: "flex-start" }}>
                {nav.map((item) => (
                  <NavItem css={{ marginRight: "40px", marginBottom: "15px" }} href={`#${item.id}`} key={item.id}>
                    {item.title}
                  </NavItem>
                ))}
              </PageNav>
              <PageNav>
                {feeds.map(({ id, title, feed }) => (
                  <NavItem href={`#${id}`} key={id}>
                    {title}
                  </NavItem>
                ))}
              </PageNav>
            </div>
          </div>
          <GeneralUpdates nodes={generalUpdates} />
          <ContentModeration nodes={contentModeration} />
          <div>
            {feeds.map(({ id, title, feed }) => <Archive key={id} id={id} title={title} feedResponse={feed} />)}
          </div>
        </div>
      </Layout>
    </div>
  );
}

export default News;

export const pageQuery = graphql`
  query News($locale: String!) {
    reports: allMdx(
      filter: {
        fields: { locale: { eq: $locale } }
        fileAbsolutePath: { regex: "/content/content-moderation/" }
        frontmatter: { draft: { ne: true } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          fields {
            slug
            locale
          }
          frontmatter {
            title
            date
            desc
            image
          }
        }
      }
    }
    generalUpdates: allMdx(
      filter: {
        fields: { locale: { eq: $locale } }
        fileAbsolutePath: { regex: "/content/news/" }
        frontmatter: { draft: { ne: true } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          fields {
            slug
            locale
          }
          frontmatter {
            title
            date
            desc
            image
          }
        }
      }
    }
  }
`;
